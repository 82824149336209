import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/Reboot/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import RebootTable from 'components/Web_User_Interface/1440p_Series/System/Reboot/rebootTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // System Reboot",
  "path": "/Web_User_Interface/1440p_Series/System/Reboot/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Use this function to reboot your camera safely. It is not recommended to simply unplug/replug your camera or use the hardware reset unnecessarily (those actions can be harmful to running operations as well as causing hardware defects on the internal SD card). Please always use the soft- restart and reset via the web user interface.",
  "image": "./WebUI_1440p_SearchThumb_System_Reboot.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_Reboot.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // System Reboot' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use this function to reboot your camera safely. It is not recommended to simply unplug/replug your camera or use the hardware reset unnecessarily (those actions can be harmful to running operations as well as causing hardware defects on the internal SD card). Please always use the soft- restart and reset via the web user interface.' image='/images/Search/WebUI_1440p_SearchThumb_System_Reboot.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_Reboot.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/Neustarten/' locationFR='/fr/Web_User_Interface/1440p_Series/System/Reboot/' crumbLabel="System Reboot" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ac54b0a8b77167983454c53e165ed70c/844cc/1440p_Settings_System_Restart.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABl0lEQVQoz23PS2sTURiH8XwLQUjBIJg5E3VhsUkmmcwlycycTKdzznQmM21Ee1FI0VqJV7DFhbjSj+Cmrgru3PnlHmlSUdDFs3v58X9rrVYL0zSXCdPk5o01etkz0vffmbw8J1ycEyy+EV41PvlKcXrBoDihfv0at+/cxbJ61OtrNBoNakKIFSYEzWaT5q0mMsnZPXhKtTen2juifDQnf/CEbPeAbH/BdH5KmBTcX2/T7dhYXZt2u8f6vY0VeJlhGEvQMExkKCm2NXmmyHSKSpNVSYx+eIw++ki+c0g8ybF7HnbfwxkM6bR71H6/+wcUSBlTliVFMUVrjVIarRVbSYKMQqJYkqUzVFAx8lw811+i7Q3r34XCEEzkBK2zKyhDqy22H79FP//MG1XxKYxZFDmv9iuSxMEduNh9fwVeQn8vFMIkDIZUZYhKA8IgQEYBQbRJtJky0yFF6PLheIfTFzGZ6uPYDo7t0e38BzSFuTwopxKV+ox8l/HQxXMGyLFPriLOzl7z88cFX97NkCNruXDsu1jdLr8A3dsARMmlpvMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ac54b0a8b77167983454c53e165ed70c/e4706/1440p_Settings_System_Restart.avif 230w", "/en/static/ac54b0a8b77167983454c53e165ed70c/d1af7/1440p_Settings_System_Restart.avif 460w", "/en/static/ac54b0a8b77167983454c53e165ed70c/7f308/1440p_Settings_System_Restart.avif 920w", "/en/static/ac54b0a8b77167983454c53e165ed70c/ff569/1440p_Settings_System_Restart.avif 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ac54b0a8b77167983454c53e165ed70c/a0b58/1440p_Settings_System_Restart.webp 230w", "/en/static/ac54b0a8b77167983454c53e165ed70c/bc10c/1440p_Settings_System_Restart.webp 460w", "/en/static/ac54b0a8b77167983454c53e165ed70c/966d8/1440p_Settings_System_Restart.webp 920w", "/en/static/ac54b0a8b77167983454c53e165ed70c/83135/1440p_Settings_System_Restart.webp 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ac54b0a8b77167983454c53e165ed70c/81c8e/1440p_Settings_System_Restart.png 230w", "/en/static/ac54b0a8b77167983454c53e165ed70c/08a84/1440p_Settings_System_Restart.png 460w", "/en/static/ac54b0a8b77167983454c53e165ed70c/c0255/1440p_Settings_System_Restart.png 920w", "/en/static/ac54b0a8b77167983454c53e165ed70c/844cc/1440p_Settings_System_Restart.png 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ac54b0a8b77167983454c53e165ed70c/c0255/1440p_Settings_System_Restart.png",
              "alt": "Web User Interface - 1440p Series - System Reboot",
              "title": "Web User Interface - 1440p Series - System Reboot",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <RebootTable mdxType="RebootTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      